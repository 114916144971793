import { put, takeLatest, call } from 'redux-saga/effects';
import getApiCaller from '../../../../getApiCaller';

const API_DATA = (action: any) => {
  const { customerId } = action.payload;
  return getApiCaller(`ingestion/RevenueSources/?customer_id=${customerId}`, 'get', true).then(
    (response) => response
  );
};

const that = this;

function* getRevenueSources(action: any) {
  yield put({ type: 'GET_REVENUE_SOURCES_STARTED' });
  try {
    const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
    yield put({
      type: 'GET_REVENUE_SOURCES_SUCCESS',
      payload: { ...DATA, status: 'success' }
    });
  } catch (error: any) {
    if (error.toString() === 'TypeError: Network request failed') {
      yield put({
        type: 'GET_REVENUE_SOURCES_NET_FAILED',
        payload: { status: 'failure', message: error.toString() }
      });
    } else {
      yield put({
        type: 'GET_REVENUE_SOURCES_FAILED',
        payload: { status: 'failure', message: error }
      });
    }
  }
}

function* getRevenueSourcesSaga() {
  yield takeLatest('GET_REVENUE_SOURCES', getRevenueSources);
}

export default getRevenueSourcesSaga;
