export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      data: {
        email: string;
      };
    };
  }
) {
  switch (action.type) {
    case 'EMAIL_EXISTS_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'EMAIL_EXISTS_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'EMAIL_EXISTS_INVALID': {
      return {
        ...state,
        changingStatus: 'invalid',
        data: action.payload
      };
    }
    case 'EMAIL_EXISTS_ALREADY': {
      return {
        ...state,
        changingStatus: 'existing',
        data: action.payload
      };
    }
    case 'EMAIL_EXISTS_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'EMAIL_EXISTS_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
