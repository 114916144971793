export default function reducer(
  state = {
    data: {
      customerId: null,
      customerUserId: null,
      customerCuid: null,
      customerName: null,
      customerType: null,
      customerDefaultCurrency: null,
      customerUserStatus: null,
      customerIsDemo: false
    }
  },
  action: {
    type: string;
    payload: {
      data: {
        customerId: string;
        customerUserId: string;
        customerCuid: string;
        customerName: string;
        customerType: string;
        customerDefaultCurrency: string;
        customerUserStatus: string;
        customerIsDemo: boolean;
      };
    };
  }
) {
  switch (action.type) {
    case 'SAVE_ACTIVE_CUSTOMER_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'SAVE_ACTIVE_CUSTOMER_SUCCESS': {
      const {
        customerId,
        customerUserId,
        customerCuid,
        customerName,
        customerType,
        customerDefaultCurrency,
        customerUserStatus,
        customerIsDemo
      } = action.payload.data;

      return {
        ...state,
        changingStatus: 'success',
        data: {
          customerId,
          customerUserId,
          customerCuid,
          customerName,
          customerType,
          customerDefaultCurrency,
          customerUserStatus,
          customerIsDemo
        }
      };
    }
    case 'SAVE_ACTIVE_CUSTOMER_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'SAVE_ACTIVE_CUSTOMER_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
