import { INIT_REVENUE_PARAMS, INIT_REVENUE_PERIODS } from '../../../models/funding.model';
import { IReduxFundingState } from './models';

const defaultState: IReduxFundingState = {
  allReportRevenuesLedger: [],
  revenuesLedgerTable: {
    resultCount: 0,
    hiddenColumns: [],
    params: INIT_REVENUE_PARAMS,
    filteredBy: {},
    availableFilters: {
      revenue_source_name: [],
      status: [],
      network: [],
      payment_status: []
    },
    allFilters: {
      revenue_source_name: [],
      status: [],
      network: [],
      payment_status: []
    },
    periods: INIT_REVENUE_PERIODS
  },
  allReportAdvancedLedger: [],
  advanceLedgerTable: {
    hiddenColumns: []
  },
  allReportTransactions: [],
  transactionsTable: {
    hiddenColumns: []
  }
};

export default defaultState;
