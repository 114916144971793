import { put, takeLatest, call } from 'redux-saga/effects';
import postApiCaller from '../../../postApiCaller';

const API_DATA = (action: any) => {
  //   const { email, password, otp, phone, code } = action.payload;
  //   const body = {
  //     email,
  //     password,
  //     otp,
  //     phone,
  //     code
  //   };
  const body = { ...action.payload };

  return postApiCaller('accounts/UpdateInvitedUser/', 'PATCH', body, false).then(
    (response) => response
  );
};

const that = this;

export const SIGNUP_SAGA = function* fetchUsers() {
  // eslint-disable-next-line
  yield takeLatest('SIGNUP', function* (action: any) {
    yield put({ type: 'SIGNUP_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      // const DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'SIGNUP_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'SIGNUP_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'SIGNUP_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
