import { createTheme } from '@material-ui/core/styles';
import { IRevvingColors, revvingColors } from './colors';

declare module '@material-ui/core/styles/createTheme' {
  interface ThemeOptions {
    revvingColors?: IRevvingColors;
  }
  interface Theme {
    revvingColors: IRevvingColors;
  }
}

const customSpacing = createTheme({
  spacing: (factor) => `${factor}px`
});

export const RevvingTheme = createTheme(
  {
    ...customSpacing,
    overrides: {
      MuiListItem: {
        root: {
          '&.Mui-selected': {
            background: revvingColors.grey.grey700,
            color: revvingColors.white.white500
          },
          '&.Mui-selected:hover': {
            background: revvingColors.grey.grey700,
            color: revvingColors.white.white500
          }
        }
      },
      MuiInputLabel: {
        root: {
          color: revvingColors.black.light,
          fontSize: '1rem',
          fontFamily: 'AvertaDemo-Regular',
          marginBottom: '1.25rem',
          textTransform: 'initial',
          '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
            fontSize: '1rem'
          }
        },
        outlined: {
          color: revvingColors.grey.grey700,
          fontSize: '0.75rem'
        }
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '0px',
          position: 'relative'
        },
        input: {
          padding: '0px 14px',
          fontSize: '1.313rem',
          height: '52px',
          '@media only screen and (min-width: 310px) and (max-width:767px)': {
            fontSize: '0.813rem',
            height: '40px'
          },
          '@media only screen and (min-width: 1251px) and (max-width:1700px)': {
            fontSize: '1rem'
          }
        },
        multiline: {
          padding: '0px'
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: revvingColors.grey.grey700,
          fontSize: '1rem',
          color: revvingColors.white.white500,
          textAlign: 'left'
        },
        arrow: {
          color: revvingColors.grey.grey700
        }
      },
      MuiSlider: {
        markLabel: {
          left: '96%',
          '@media only screen and (min-width: 1251px) and (max-width: 1400px)': {
            left: '96%'
          }
        }
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          borderLeft: 'none'
        },
        outlined: {
          borderLeft: 'none',
          borderRadius: 0,
          position: 'relative',
          transition: 'none'
        }
      },
      MuiInputBase: {
        root: {
          padding: '0px',
          '&.custInputSize': {
            MuiOutlinedInput: {
              input: {
                padding: '6.5px 14px'
              }
            }
          }
        },
        input: {
          '&.Mui-focused': {
            border: 'none'
          }
        },
        multiline: {
          padding: '0px'
        }
      },
      MuiFormGroup: {
        root: {
          marginBottom: '20px',
          '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            marginBottom: '5px'
          }
        }
      },
      MuiFormControl: {
        root: {
          width: '100%'
        }
      },
      MuiDialog: {
        paperWidthSm: {
          minWidth: '480px',
          '@media only screen and (max-width: 767px)': {
            minWidth: 'unset'
          }
        }
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'flex-start',
          paddingLeft: '1.5rem',
          paddingBottom: '1.5rem'
        }
      },
      MuiTypography: {
        h6: {
          fontSize: '1.25rem',
          fontWeight: 'bold',
          fontFamily: 'AvertaDemo-Regular',
          color: revvingColors.black.dark
        }
      },
      MuiCheckbox: {
        colorPrimary: { color: revvingColors.purple.purple300 }
      }
    },
    typography: {
      fontFamily: 'Averta-Semibold',
      subtitle1: {
        fontSize: '2.375rem',
        lineHeight: '40px',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
          fontSize: '20px',
          lineHeight: '22px',
          marginBottom: '5px'
        }
      },
      subtitle2: {
        fontSize: '1.75rem',
        lineHeight: '30px',
        color: revvingColors.grey.grey700,
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
          fontSize: '20px',
          lineHeight: '22px',
          marginBottom: '5px'
        }
      },
      body2: {
        fontSize: '0.875rem'
        // color: '#e41c1c'
      },
      h1: {
        fontSize: '1.5rem',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
          fontSize: '1.25rem',
          lineHeight: '22px'
        }
      },
      h2: {
        fontSize: '1.3rem'
        // 22px
      },
      h3: {
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: '25px',
        fontFamily: 'AvertaDemo-Regular',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
          fontSize: '15px',
          lineHeight: '22px'
        }
      },
      h4: {
        fontSize: '1.125rem'
        // 18px
      },
      h5: {
        fontSize: '1rem'
        // 16px
      },
      h6: {
        fontSize: '1rem',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
          fontSize: '16px',
          lineHeight: '22px'
        }
      },
      body1: {
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
          fontSize: '12px'
        }
      }
    }
  },
  {
    revvingColors
  }
);
