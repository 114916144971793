import { put, takeLatest, call } from 'redux-saga/effects';
import postApiCaller from '../../../postApiCaller';

const API_DATA = (action: any) => {
  const { id } = action.payload;
  const data = {
    active: true
  };

  return postApiCaller(`/ingestion/KantoxAccountUD/${id}`, 'PATCH', data, true).then(
    (response) => response
  );
};

const that = this;

export const ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_SAGA = function* fetchAccounts() {
  // eslint-disable-next-line
  yield takeLatest('ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT', function* (action: any) {
    yield put({ type: 'ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
