import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Media from 'react-media';
// TODO: Shahzad why is this CSS here?
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { ThemeProvider } from '@material-ui/core';
import requireAuth from '../hoc/withAuth';
import Loader from '../containers/Loading';
// import { RevvingTheme } from '../theme';
// import  {ThemeProvider}  from 'material-ui/styles';

const Login = lazy(() => import('../containers/SignIn'));
const Signup = lazy(() => import('../containers/SignUp'));
const Landing = lazy(() => import('../containers/CustomerPortal/PortalFunding/PortalFunding'));
const Guest = lazy(() => import('../containers/Guest/Guest'));
const Eligibility = lazy(() => import('../containers/Eligibility'));
const RevenuesLedger = lazy(() => import('../containers/CustomerPortal/RevenuesLedger'));
const Transactions = lazy(() => import('../containers/CustomerPortal/Transactions'));
const AdvanceLedger = lazy(() => import('../containers/CustomerPortal/AdvanceLedger'));
const Integrations = lazy(() => import('../containers/Account/Integrations'));
const Fees = lazy(() => import('../containers/Account/Fees'));
const Documents = lazy(() => import('../containers/Account/Documents'));
const BusinessDetails = lazy(() => import('../containers/Account/BusinessDetails'));
const Administration = lazy(() => import('../containers/Account/Administration'));
/* to do */
const BankAccounts = lazy(() => import('../containers/Account/BankAccounts'));
const PageNotFound = lazy(() => import('../containers/PageNotFound/default'));
const PageNotAuth = lazy(() => import('../containers/PageNotAuth/default'));
const MobilePageNotFound = lazy(() => import('../containers/PageNotFound/mobile-page-not-found'));
const ForgetPassword = lazy(() => import('../containers/ForgetPassword'));

// google tag manager analytics + hotjar: removing for now
// import TagManager from 'react-gtm-module';
// if (process.env.NODE_ENV === 'production') {
// const tagManagerArgs = {
// gtmId: 'GTM-57KKDWK'
// };
// TagManager.initialize(tagManagerArgs);
// TagManager.initialize({ gtmId: 'G-20FYVSL3HT' });
// }

const Routes = () => (
  <Router>
    <Suspense fallback={<Loader />}>
      <Media query='(min-width: 1024px)'>
        {(matches) =>
          matches ? (
            <Switch>
              {/*  Router for screens 1024px or more  */}
              {/*  Public Routes  */}
              <Route exact path='/eligibility-check' component={Eligibility} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/sign-up' component={Signup} />
              <Route exact path='/forget-password' component={ForgetPassword} />
              <Route exact path='/403' component={PageNotAuth} />
              {/*  Auth Routes  */}
              <Route exact path='/' component={requireAuth(Landing)} />
              <Route exact path='/guest' component={requireAuth(Guest)} />
              <Route exact path='/funding/revenues' component={requireAuth(RevenuesLedger)} />
              <Route exact path='/funding/advances' component={requireAuth(AdvanceLedger)} />
              <Route exact path='/funding/transactions' component={requireAuth(Transactions)} />
              <Route exact path='/funding/documents' component={requireAuth(Documents)} />
              <Route
                exact
                path='/revenue-sources/integrations'
                component={requireAuth(Integrations)}
              />
              <Route exact path='/revenue-sources/fees' component={requireAuth(Fees)} />
              <Route
                exact
                path='/account/business-details'
                component={requireAuth(BusinessDetails)}
              />
              <Route exact path='/account/administration' component={requireAuth(Administration)} />
              <Route exact path='/account/bank-accounts' component={requireAuth(BankAccounts)} />
              {/*  Page not found Route  */}
              <Route path='*' component={PageNotFound} />
            </Switch>
          ) : (
            <Switch>
              {/*  Router for screens less than 1024px  */}
              {/*  Public Routes  */}
              <Route exact path='/eligibility-check' component={Eligibility} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/forget-password' component={ForgetPassword} />
              {/* Routes which display "unsuitable for small screen" page */}
              {[
                '/', // dashboard
                // '/login',
                '/sign-up',
                // '/forget-password',
                '/funding/revenues',
                '/funding/advances',
                '/funding/transactions',
                '/funding/documents',
                '/revenue-sources/integrations',
                '/revenue-sources/fees',
                '/account/business-details',
                '/account/administration'
              ].map((mobileUrl) => (
                <Route key={mobileUrl} path={mobileUrl} component={MobilePageNotFound} />
              ))}
              {/*  Page not found Route  */}
              <Route path='*' component={PageNotFound} />
            </Switch>
          )
        }
      </Media>
    </Suspense>
  </Router>
);

export default Sentry.withProfiler(Routes);
