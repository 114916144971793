export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      customerCuid: string;
      currency_id: number;
      iban: string;
      bicSwift: string;
      accountHolder: string;
      country_code: string;
      address1: string;
      address2: string;
      postcode: string;
    };
  }
) {
  switch (action.type) {
    case 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_NET_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    case 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT': {
      return {
        ...state,
        changingStatus: ''
      };
    }
    default: {
      return state;
    }
  }
}
