export interface IRevvingColors {
  green: {
    light: string;
    default: string;
    dark: string;
  };
  yellow: {
    light: string;
    default: string;
  };
  red: {
    light: string;
    default: string;
    dark: string;
  };
  grey: {
    grey100: string;
    grey200: string;
    grey300: string;
    grey400: string;
    grey500: string;
    grey600: string;
    grey700: string;
    grey800: string;
    grey900: string;
    grey1000: string;
    grey1100: string;
  };
  black: {
    light: string;
    default: string;
    dark: string;
  };
  purple: {
    purple100: string;
    purple200: string;
    purple300: string;
    purple400: string;
    purple500: string;
  };
  pink: {
    light: string;
    default: string;
  };
  orange: {
    default: string;
  };
  blue: {
    light: string;
    default: string;
    dark: string;
  };
  white: {
    white100: string;
    white200: string;
    white300: string;
    white400: string;
    white500: string;
  };
  background: {
    background100: string;
    background200: string;
    background300: string;
    background400: string;
    background500: string;
    background600: string;
    background700: string;
  };
}
export const revvingColors: IRevvingColors = {
  green: {
    light: '#83e886',
    default: '#0f0',
    dark: '#1d8220'
  },
  yellow: {
    light: '#ffd670',
    default: '#ffbc3d'
  },
  red: {
    light: '#fcb1a7',
    default: '#f44336',
    dark: '#d32f2f'
  },
  grey: {
    grey100: '#c3c3c3',
    grey200: '#c4c4c4',
    grey300: '#c5c5c5',
    grey400: '#c6c6c6',
    grey500: '#a8a4a4',
    grey600: '#a8a8a8',
    grey700: '#3c3c3c',
    grey800: '#3d3d3d',
    grey900: '#706b6b',
    grey1000: '#434343',
    grey1100: '#757575'
  },
  black: {
    light: '#343434',
    default: '#221616',
    dark: '#000000'
  },
  purple: {
    purple100: '#8181eb',
    purple200: '#3f51b5',
    purple300: '#5b47de',
    purple400: '#5956e6',
    purple500: '#b5c5fb'
  },
  pink: {
    light: '#fcb1a7',
    default: '#ff478f'
  },
  orange: {
    default: '#e1803b'
  },
  blue: {
    light: '#88b7d5',
    default: '#262f3a',
    dark: '#5956e613'
  },
  white: {
    white100: '#eeeeee',
    white200: '#f5f5f5',
    white300: '#f9f4f4',
    white400: '#e8e8e8',
    white500: '#fff'
  },
  background: {
    background100: '#dfd4fa',
    background200: '#c0c0c0',
    background300: '#ececec',
    background400: '#c6c5c0',
    background500: '#f1f1f1',
    background600: 'transparent',
    background700: '#eeeeeee5'
  }
};
