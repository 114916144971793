import { put, takeLatest, call } from 'redux-saga/effects';
import postApiCaller from '../../../../postApiCaller';

const API_DATA = (action: any) => {
  const {
    customer_id,
    name = '',
    message = '',
    request = '',
    revenue_source = '',
    currency = ''
  } = action.payload;
  const data = {
    name,
    customer_id,
    message,
    request,
    revenue_source,
    currency
  };
  return postApiCaller('accounts/CustomerRevenueSource/', 'post', data, true).then(
    (response) => response
  );
};

const that = this;

export const GET_UPDATE_REVENUE_SOURCE_SAGA = function* fetchUsers() {
  // eslint-disable-next-line
  yield takeLatest('GET_UPDATE_REVENUE_SOURCE', function* (action: any) {
    yield put({ type: 'GET_UPDATE_REVENUE_SOURCE_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      // const DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'GET_UPDATE_REVENUE_SOURCE_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'GET_UPDATE_REVENUE_SOURCE_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'GET_UPDATE_REVENUE_SOURCE_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
