export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      week: string;
      currency: string;
      revenue_source_name: string;
      customer: string;
      transaction_type: string;
      start_date: string;
      end_date: string;
      payment_reference: string;
      collection_reference: string;
      document_number: string;
      invoicing_month: string;
      is_recovery: string;
      transaction_type_multiple: string;
      page: number;
      page_size: number;
    };
  }
) {
  switch (action.type) {
    case 'GROUPED_REVENUE_TRANSACTION_TYPE_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GROUPED_REVENUE_TRANSACTION_TYPE_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'GROUPED_REVENUE_TRANSACTION_TYPE_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'GROUPED_REVENUE_TRANSACTION_TYPE_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
