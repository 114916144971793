/* eslint-disable func-names */
import { put, takeLatest, call } from 'redux-saga/effects';
import postApiCaller from '../../../postApiCaller';

const API_DATA = (action: any) =>
  postApiCaller('accounts/CheckUserExists/', 'POST', action.payload, false).then(
    (response) => response
  );

const that = this;

export const EMAIL_EXISTS_SAGA = function* fetchUsers() {
  yield takeLatest('EMAIL_EXISTS', function* (action: any) {
    yield put({ type: 'EMAIL_EXISTS_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'EMAIL_EXISTS_SUCCESS',
        payload: { code: 200, status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'EMAIL_EXISTS_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else if (error.code === 400) {
        yield put({
          type: 'EMAIL_EXISTS_INVALID',
          payload: { ...error, status: 'invalid', message: error.codeText }
        });
      } else if (error.code === 403) {
        yield put({
          type: 'EMAIL_EXISTS_ALREADY',
          payload: { ...error, status: 'existing', message: error.codeText }
        });
      } else {
        yield put({
          type: 'EMAIL_EXISTS_FAILED',
          payload: { ...error, status: 'failure', message: error }
        });
      }
    }
  });
};
