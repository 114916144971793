import { put, takeLatest, call } from 'redux-saga/effects';
import postApiCaller from '../../../postApiCaller';

const API_DATA = (action: any) => {
  const {
    customerCuid,
    currency_id,
    iban,
    bicSwift,
    accountHolder,
    country_code,
    address1,
    address2,
    postcode
  } = action.payload;
  const data = {
    currency_id,
    customer_cuid: customerCuid,
    iban,
    swift_bic: bicSwift,
    account_holder: accountHolder,
    country_code,
    address_one: address1,
    address_two: address2,
    postcode,
    verified: false,
    active: false
  };
  return postApiCaller('ingestion/KantoxAccounts/', 'POST', data, true).then(
    (response) => response
  );
};

const that = this;

export const ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_SAGA = function* fetchAccounts() {
  // eslint-disable-next-line
  yield takeLatest('ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT', function* (action: any) {
    yield put({ type: 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
