import { put, takeLatest, call } from 'redux-saga/effects';
import postApiCaller from '../../../../postApiCaller';

const API_DATA = (action: any) => {
  const { cuid, registeredName, registrationNumber, addressLine1, addressLine2, city, postcode } =
    action.payload;

  const body = {
    cuid,
    name: registeredName,
    company_number: registrationNumber,
    address_one: addressLine1,
    address_two: addressLine2,
    city,
    postcode
  };

  return postApiCaller(`ingestion/Customer/${cuid}`, 'PATCH', body, true).then(
    (response) => response
  );
};

const that = this;

export const UPDATE_BUSINESS_SAGA = function* fetchUsers() {
  // eslint-disable-next-line
  yield takeLatest('UPDATE_BUSINESS', function* (action: any) {
    yield put({ type: 'UPDATE_BUSINESS_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      // const DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'UPDATE_BUSINESS_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'UPDATE_BUSINESS_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'UPDATE_BUSINESS_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
