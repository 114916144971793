export const checkStatus = (response: any) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 500) {
    return response.json().then((errorData: any) => {
      const message = (errorData || {}).error;
      const error: any = new TypeError(message || 'Server error');
      throw error;
    });
  }
  const urlArray = response.url.split('/');
  if (urlArray[4] !== 'Sendsms' && response.status === 401) {
    // if user token no longer valid BUT not for incorrect email+password on login
    localStorage.clear();
    window.location.href = '/403';
  }
  if (response.status >= 400 && response.status < 500) {
    /* eslint-disable no-console */
    console.log('response 400 - 500');
    console.log('response.status', response.status);
    console.log('response.url', response.url);
    console.log('response.type', response.type);
    return response.json().then((errorData: any) => {
      const error: any = {
        code: response.status,
        codeText: response.statusText,
        data: errorData.detail ? errorData.detail : 'Something went wrong',
        ...errorData
      };
      throw error;
    });
  }
  return response.json().then((errorData: any) => {
    const error: any = {
      code: response.status,
      data: errorData
    };
    throw error;
  });
};

export const parseJSON = async (response: any) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const json = await response.json();
    return json;
  }
  return null;
};
