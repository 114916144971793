export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      data: {
        customer_user_status: string;
        customers: {
          customer_cuid: string;
          customer_default_currency: string;
          customer_id: number;
          customer_name: string;
          customer_user_id: number;
          status: string;
        }[];
        email: string;
        first_name: string;
        has_customer: boolean;
        is_owner: boolean;
        last_name: string;
        phone: string;
        terms: boolean;
        username: string;
      };
      status: string;
    };
  }
) {
  switch (action.type) {
    case 'GET_USER_PROFILE_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_USER_PROFILE_DATA_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'GET_USER_PROFILE_DATA_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'GET_USER_PROFILE_DATA_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
