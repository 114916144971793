import { combineReducers } from 'redux';
import login from './Login/loginReducer';
import businessType from './Eligibility/BusinessTypeReducer';
import emailExists from './Eligibility/EmailExistsReducer';
import stepperReducer from './Eligibility/SaveStepperReducer';
import createCustomerReducer from './Eligibility/CreateCustomerReducer';
import sendGridTemplateReducer from './SendGridTemplateReducer';
import currentUserIdReducer from './Eligibility/CurrentUserIdReducer';
import sendSmsReducer from './Sms/SmsReducer';
import groupedRevenueReducer from './Funding/GroupedRevenueReducer';
import groupedCurrencyReducer from './Funding/GroupedCurrencyReducer';
import groupedTypeReducer from './Funding/GroupTransTypeReducer';
import groupedRevenueTypeReducer from './Funding/GroupRevenueTransTypeReducer';
import groupedTransactionWeekReducer from './Funding/GroupedTransactionWeekReducer';
import saveUserReducer from './ProfileAccounts/UserReducer';
import userProfileReducer from './ProfileAccounts/UserProfile';
import saveActiveCustomerReducer from './ProfileAccounts/SaveActiveCustomer';
import lastestAdvancesReducer from './Funding/LatestAdvances';
import feeReducer from './Account/FeeReducer';
import accountPreferencesReducer from './ProfileAccounts/GetAccountPreferencesReducer';
import updateAccPreferencesReducer from './ProfileAccounts/UpdateAccPreferencesReducer';
import getRevenueSourcesReducer from './ProfileAccounts/Integrations/GetRevenueSourcesReducer';
import addRevenueSourceReducer from './ProfileAccounts/Integrations/AddRevenueSourceReducer';
import getBusinessReducer from './ProfileAccounts/Business/GetBusinessReducer';
import updateBusinessReducer from './ProfileAccounts/Business/UpdateBusinessReducer';
import GetAccountingSoftwareReducer from './Account/GetAccountingSoftware';
import CustomerRevenueSource from './AdvanceLedger/RevenueSource';
import ForgetPassword from './ForgetPassword/ForgetPasswordCreate';
import forgetPasswordVerification from './ForgetPassword/ForgetPasswordVerification';
import ResetPassword from './ForgetPassword/ResetPassword';
import getGroupedArrowData from './Funding/FundingArrowReducer';
import addTeamUser from './Account/Administration/addTeamUser';
import customerUsers from './Account/Administration/getCustomerUsers';
import removeTeamUser from './Account/Administration/removeTeamUser';
import updateUserRoleReducer from './Account/Administration/updateUserRole';
import Documentation from './Documentation';
import UpdateRevenueSource from './ProfileAccounts/Integrations/UpdateRevenueSource';
import UpdateAccSoftwareSource from './Account/UpdateAccSoftware';
import VerifyPhoneNumber from './VerifyPhoneNumber/VerifyPhoneNumberReducer';
import SignUpReducer from './Signup/SignupReducer';
import getDetailsFromCode from './GetDetailsFromCode';
import CurrenciesListReducer from './DbOptions/CurrenciesListReducer';
import CurrenciesObject from './DbOptions/CurrenciesObject';
import modal from './ShowModal';
import revenueSourcesReducer from './revenueSources/reducer';
import common from './common/reducer';
import fundingReducer from './fundingReducer/reducer';
import customerBankAccountCollections from './Account/BankAccount/collections';
import customerBankAccountPayouts from './Account/BankAccount/payouts';
import addCustomerBankAccountPayout from './Account/BankAccount/addPayout';
import activateCustomerBankAccountPayout from './Account/BankAccount/activatePayout';

const appReducer = combineReducers({
  modal,
  getGroupedArrowData,
  login,
  businessType,
  emailExists,
  sendSmsReducer,
  stepperReducer,
  createCustomerReducer,
  sendGridTemplateReducer,
  currentUserIdReducer,
  groupedRevenueReducer,
  groupedCurrencyReducer,
  groupedTypeReducer,
  groupedRevenueTypeReducer,
  groupedTransactionWeekReducer,
  saveUserReducer,
  userProfileReducer,
  saveActiveCustomerReducer,
  lastestAdvancesReducer,
  feeReducer,
  accountPreferencesReducer,
  updateAccPreferencesReducer,
  getRevenueSourcesReducer,
  addRevenueSourceReducer,
  updateBusinessReducer,
  getBusinessReducer,
  GetAccountingSoftwareReducer,
  addTeamUser,
  removeTeamUser,
  Documentation,
  CustomerRevenueSource,
  ForgetPassword,
  forgetPasswordVerification,
  ResetPassword,
  UpdateRevenueSource,
  UpdateAccSoftwareSource,
  VerifyPhoneNumber,
  SignUpReducer,
  getDetailsFromCode,
  customerUsers,
  CurrenciesListReducer,
  CurrenciesObject,
  updateUserRoleReducer,
  revenueSourcesReducer,
  common,
  fundingReducer,
  customerBankAccountCollections,
  customerBankAccountPayouts,
  addCustomerBankAccountPayout,
  activateCustomerBankAccountPayout
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'CLEAR_STATE') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
