import { put, takeLatest } from 'redux-saga/effects';

export const SAVE_ACTIVE_CUSTOMER_SAGA = function* saveActiveCustomer() {
  // eslint-disable-next-line
  yield takeLatest('SAVE_ACTIVE_CUSTOMER', function* (action: any) {
    yield put({ type: 'SAVE_ACTIVE_CUSTOMER_STARTED' });
    try {
      const DATA = action.payload;
      yield put({
        type: 'SAVE_ACTIVE_CUSTOMER_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'SAVE_ACTIVE_CUSTOMER_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'SAVE_ACTIVE_CUSTOMER_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
