import { put, takeLatest, call } from 'redux-saga/effects';
import { PreferencesType } from '../../../../components/AuthLayout/EmailPreferences';
import postApiCaller from '../../../postApiCaller';

const API_DATA = (action: any) => {
  const { notifyAdvanceDeposit, notifyRepayment, notifySettingsId }: PreferencesType =
    action.payload;

  const receiveEmails = notifyAdvanceDeposit || notifyRepayment;
  const body = {
    email_notifications: receiveEmails,
    notify_advance_deposit: notifyAdvanceDeposit,
    notify_repayment: notifyRepayment
  };

  return postApiCaller(`accounts/UserSettings/${notifySettingsId}`, 'PATCH', body, true).then(
    (response) => response
  );
};

const that = this;

export const UPDATE_ACCOUNT_PREFERENCES_SAGA = function* fetchUsers() {
  // eslint-disable-next-line
  yield takeLatest('UPDATE_ACCOUNT_PREFERENCES', function* (action: any) {
    yield put({ type: 'UPDATE_ACCOUNT_PREFERENCES_STARTED' });
    try {
      const DATA: typeof API_DATA = yield call(API_DATA.bind(that, action));
      // const DATA = yield call(API_DATA.bind(that, action));
      yield put({
        type: 'UPDATE_ACCOUNT_PREFERENCES_SUCCESS',
        payload: { status: 'success', data: DATA }
      });
    } catch (error: any) {
      if (error.toString() === 'TypeError: Network request failed') {
        yield put({
          type: 'UPDATE_ACCOUNT_PREFERENCES_NET_FAILED',
          payload: { status: 'failure', message: error.toString() }
        });
      } else {
        yield put({
          type: 'UPDATE_ACCOUNT_PREFERENCES_FAILED',
          payload: { status: 'failure', message: error }
        });
      }
    }
  });
};
