export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      data: {
        dynamic_template_data: {
          description: string;
        };
        from: string;
        to: string;
        template_id: string;
      };
    };
  }
) {
  switch (action.type) {
    case 'SEND_GRID_TEMPLATE_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'SEND_GRID_TEMPLATE_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'SEND_GRID_TEMPLATE_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'SEND_GRID_TEMPLATE_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
