import * as Sentry from '@sentry/react';
import { checkStatus, parseJSON } from '../utils/responseHandler';

const URL = process.env.REACT_APP_BASE_URL;
const BASIC_AUTH = process.env.REACT_APP_BASIC_AUTH;

// Make an api call
const async = (url: string, method = 'get', authorization: boolean) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: ''
  };
  let authString = '';
  if (BASIC_AUTH && BASIC_AUTH !== '') {
    authString = authString.concat(`Basic ${BASIC_AUTH}`, ' ');
  }
  if (authorization) {
    authString = authString.concat(`Bearer ${localStorage.getItem('CUSTOMER_TOKEN')}`);
  }
  headers.Authorization = authString;

  return fetch(`${URL}${url}`, {
    method,
    headers
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      Sentry.captureException(error, { tags: { src: 'getApi' } });
      throw error;
    });
};
export default async;
