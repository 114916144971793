interface IFundingApiResponse {
  count: number;
  next: string | null;
  page_count: number;
  previous: string | null;
}
export interface IFundingLedgerResponse extends IFundingApiResponse {
  results: {
    last_updated: string;
    data: any[];
  };
}

export interface IReportAdvancedLedgerResponse {
  count: number | null;
  results: {
    last_updated: string;
    data: any[];
  };
}

export interface IReportAdvancedLedger {
  customer_id: number;
  last_year: IReportAdvancedLedgerResponse | null;
  historical: IReportAdvancedLedgerResponse | null;
}

export const FILTER_STATUS_FUNDING = [
  'All status',
  'Open',
  'Closed',
  'Overdue',
  'Recovery'
] as const;
export const FILTER_PERIOD_FUNDING = [
  'All periods',
  'This month',
  'Last month',
  'Last 3 months',
  'Year to date',
  'Custom'
] as const;
export const FILTER_WHICH_PERIOD_FUNDING = ['All dates', 'Expected Repayment', 'Month end date'];

// prettier-ignore
// eslint-disable-next-line prettier/prettier
export type TypeFilterStatus = (typeof FILTER_STATUS_FUNDING)[number];
// prettier-ignore
// eslint-disable-next-line prettier/prettier
export type TypeFilterPeriod = (typeof FILTER_PERIOD_FUNDING)[number];

export interface IFundingDateFilterResponse {
  name: string | null;
  status: string | null;
  dates: {
    [x: string]: string | null;
  };
}

export interface IAdvancedLedgerFilters {
  status: TypeFilterStatus;
  source: string;
  period: TypeFilterPeriod;
  expected_repayment: TypeFilterPeriod;
  search: string;
}

export interface IRangeDates {
  startDate: Date;
  endDate: Date;
  key: string;
}

export interface IDownloadSelectOption {
  option: string;
  value: string;
}
export const DOWNLOAD_OPTIONS: IDownloadSelectOption[] = [
  {
    option: 'Download',
    value: 'download'
  },
  {
    option: 'CSV',
    value: 'get_csv'
    /* },
  {
    option: 'Excel',
    value: 'get_excel'
  },
  {
    option: 'PDF',
    value: 'get_pdf' */
  }
];

export interface IAdvanceLedgerItem {
  status: string | null;
  type: string;
  revenue_source: string;
  referrer_name: string;
  dateYear: Date | null;
  docNumber: string;
  currency: string;
  purchaseValue: number | null;
  invoice_value: number | null;
  advanced_amount: number | null;
  advanced_fee: number | null;
  fee_adjustment: number | null;
  repayment: number | null;
  depreciation: number | null;
  outstanding: number | null;
  collected: number | null;
  surplus_distribution: number | null;
  exp_repayment_date: Date | null;
  invoice_date: Date | null;
  collection_date: Date | null;
  repayment_date: Date | null;
  payment_ref: string;
  payment_id: string;
  subRows?: IAdvanceLedgerItem[];
}

export interface IReportTransactionsResponse extends IReportAdvancedLedgerResponse {
  next?: string | null;
}
export interface IReportTransactions {
  customer_id: number;
  last_year: IReportTransactionsResponse | null;
  historical: IReportTransactionsResponse | null;
}
export interface ITransactionItem {
  event_date: Date;
  transaction_type: string;
  revenue_source_name: string;
  payor_name: string;
  payor_name_to_show: string;
  referrer_name: string;
  currency: string;
  amount: number | null;
  payment_id: string;
  actual_payment_id: string;
  transaction_id: string;
  document_number: string;
  uuid: string;
}

export interface ITransactionType {
  label: string;
  children: string[];
}
export type ITransactionTypes = ITransactionType[];
export const TRANSACTION_TYPES_OPTIONS: ITransactionTypes = [
  {
    label: 'All Cash Movements',
    children: [
      'Advance Payment',
      'Collection',
      'Surplus Distribution Payment',
      'Repayment',
      'Revving Repayment'
    ]
  },
  {
    label: 'All Accounting Movements',
    children: [
      'Invoice Advance',
      'Invoice Advance Fee',
      'Invoice Repayment',
      'Invoice Depreciation',
      'Invoice Fee Adjustment',
      'Invoice Final Fee'
    ]
  }
];

/* export interface ITransactionTypes {
  [x: string]: string[];
} */
export interface ITransactionsFilters {
  transaction_type: string[];
  source: string;
  currencies: string;
  period: TypeFilterPeriod;
  search: string;
}

/* revenues types */

export interface IRevenueParams {
  page: number;
  page_size: number;
  ordering: string;
  search: string;
}
export const INIT_REVENUE_PARAMS: IRevenueParams = {
  page: 1,
  page_size: 20,
  ordering: '-event_date',
  search: ''
} as IRevenueParams;
export interface IRevenueDateFilters {
  event_date: string;
  approval_date: string;
  expected_repayment_date: string;
  paid_date: string;
}
export const INIT_REVENUE_PERIODS: IRevenueDateFilters = {
  event_date: FILTER_PERIOD_FUNDING[0],
  approval_date: FILTER_PERIOD_FUNDING[0],
  expected_repayment_date: FILTER_PERIOD_FUNDING[0],
  paid_date: FILTER_PERIOD_FUNDING[0]
} as IRevenueDateFilters;
export interface RevenueFilters {
  status?: string;
  payment_status?: string;
  revenue_source_name?: string;
  network?: string;
  start_event_date?: string;
  end_event_date?: string;
  start_approval_date?: string;
  end_approval_date?: string;
  start_expected_repayment_date?: string;
  end_expected_repayment_date?: string;
  start_paid_date?: string;
  end_paid_date?: string;
}
export interface RevenueFiltersOptions {
  status?: string[];
  revenue_source_name?: string[];
  network?: string[];
  /* period?: string[];
  approval_date?: string[];
  expected_repayment?: string[];
  paid_period?: string[]; */
  // search?: string;
}
export interface IReportRevenuesLedgerResponse extends IReportAdvancedLedgerResponse {
  next?: string | null;
}
export interface IReportRevenuesLedger {
  customer_id: number;
  data?: IFundingLedgerResponse | null;
  last_year?: IReportRevenuesLedgerResponse | null;
  historical?: IReportRevenuesLedgerResponse | null;
}

export interface IAdvanceRevenue {
  advance_amount: string;
  advance_currency: string;
}
export interface IRevenuesLedgerItem {
  network: string;
  revenue_source: string;
  currency: string;
  commision_id: number;
  amount: string;
  status: string;
  event_date: Date;
  approval_date: Date;
  expected_repayment_date: Date;
  paid_date: Date;
  advance_id: string;
  advance_amount: IAdvanceRevenue;
  // billing_period_id: string;
}

export const FILTER_STATUS_REVENUE_FUNDING = [
  'All status',
  'New',
  'Approved',
  'Rejected',
  'Paid',
  'Overdue'
] as const;
// prettier-ignore
// eslint-disable-next-line prettier/prettier
export type TypeFilterRevenueStatus = (typeof FILTER_STATUS_REVENUE_FUNDING)[number];
export interface IRevenuesFilters {
  status: TypeFilterRevenueStatus;
  network: string;
  revenue_source_name: string;
  event_date: TypeFilterPeriod;
  approval_date: TypeFilterPeriod;
  expected_repayment: TypeFilterPeriod;
  paid_date: TypeFilterPeriod;
  search: string;
}

export interface IRevenuesFilterOptions {
  status: string[];
  payment_status: string[];
  network: string[];
  revenue_source_name: string[];
}
