export default function reducer(
  state = {
    data: {
      all: {},
      factored: {}
    }
  },
  action: any
) {
  switch (action.type) {
    case 'CURRENCIES_OBJECT_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'CURRENCIES_OBJECT_SUCCESS': {
      const { all, factored, kantox } = action.payload.data;

      return {
        ...state,
        changingStatus: 'success',
        data: { all, factored, kantox }
      };
    }
    case 'CURRENCIES_OBJECT_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'CURRENCIES_OBJECT_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
