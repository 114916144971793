import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import Gleap from 'gleap';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
// import { ThemeProvider } from '@material-ui/core';
import store from './redux/store';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { RevvingTheme } from './theme';

const gleapEnv = process.env.REACT_APP_GLEAP_ENV || 'dev';
const gleapAppId = process.env.REACT_APP_GLEAP_APP_ID || '';
// Please make sure to call this method only once!
if (gleapAppId !== '') {
  Gleap.initialize(gleapAppId);
  Gleap.setEnvironment(gleapEnv as 'dev' | 'staging' | 'prod');
  Gleap.setLanguage('en');
}

Sentry.init({
  dsn: 'https://bb1a8a919ae743d6955ca46267ee8004@o1399576.ingest.sentry.io/6727156',
  environment: process.env.REACT_APP_VERCEL_ENV || 'development',
  integrations: [new BrowserTracing()],
  release: `javascript-react@${process.env.REACT_APP_SENTRY_RELEASE}` || undefined,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_VERCEL_ENV === 'production' ? 0.2 : 1.0,
  enabled:
    process.env.REACT_APP_VERCEL_ENV === 'production' ||
    process.env.REACT_APP_VERCEL_ENV === 'preview'
});

const persistor = persistStore(store);

// const theme = createTheme({ typography: { fontFamily: ['AvertaDemo-Regular'].join(',') } });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={RevvingTheme}>
            <SCThemeProvider theme={RevvingTheme}>
              <CssBaseline />
              <Routes />
            </SCThemeProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
