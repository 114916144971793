/* eslint-disable @typescript-eslint/indent */

export default function reducer(
  state: {
    data: {
      data: {
        access: string;
        expire_at: string;
        refresh: string;
      };
    } | null;
  } = {
    data: null
  },
  action: {
    type:
      | 'LOGIN_USER_STARTED'
      | 'LOGIN_USER_SUCCESS'
      | 'LOGIN_USER_NET_FAILED'
      | 'LOGIN_USER_FAILED';
    payload?: {
      data?: {
        access: string;
        expire_at: string;
        refresh: string;
      };
    };
  }
) {
  switch (action.type) {
    case 'LOGIN_USER_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'LOGIN_USER_SUCCESS': {
      const expire_at = action.payload?.data?.expire_at as string; // if success, data exists.
      const access = action.payload?.data?.access as string;
      const refresh = action.payload?.data?.refresh as string;
      const expiry_string = new Date(expire_at).getTime().toString();
      localStorage.setItem('EXPIRE_AT', expiry_string);
      localStorage.setItem('CUSTOMER_TOKEN', access);
      localStorage.setItem('REFRESH_TOKEN', refresh);
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'LOGIN_USER_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'LOGIN_USER_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
