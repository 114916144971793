export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      request_status: string;
      request: string;
      current_state: string;
      customer: string;
      ordering: string;
      page: number;
      page_size: number;
    };
  }
) {
  switch (action.type) {
    case 'GET_UPDATE_REVENUE_SOURCE_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_UPDATE_REVENUE_SOURCE_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'GET_UPDATE_REVENUE_SOURCE_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'GET_UPDATE_REVENUE_SOURCE_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
