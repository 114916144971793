import styled from 'styled-components';
import { Box, Stepper } from '@material-ui/core';

const theme = {
  colors: {
    primary: '#8181eb'
  }
};

export const MainContentBox = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: white;
  z-index: 999;
`;

export const BoxWrap = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48%;
  padding: ${(props) => props.theme.spacing(50)};
  text-align: center;
  background: ${(props) => props.theme.revvingColors.white.white500};
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 88%;
  }
`;

export const DisplayStepper = styled(Stepper)`
  width: 100%;
  .MuiTypography-body2 {
    // font-size: 1rem;
  }
  .MuiStepIcon-root.MuiStepIcon-completed,
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${theme.colors.primary};
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: 600;
    color: ${theme.colors.primary};
  }
`;
