import { all } from 'redux-saga/effects';
import { LOGIN_USER_SAGA } from './Login/loginSaga';
import { BUSINESS_STATS_SAGA } from './Eligibility/BusinessTypeSaga';
import { EMAIL_EXISTS_SAGA } from './Eligibility/EmailExistsSaga';
import { SAVE_STEPPER_SAGA } from './Eligibility/StepperSaga';
import { CURRENT_USER_ID_SAGA } from './Eligibility/CurrentUserIdSaga';
import { CREATE_CUSTOMER_SAGA } from './Eligibility/CreateUserSaga';
import { SEND_GRID_TEMPLATE_SAGA } from './SendGridTemplateSaga';
import { SEND_SMS_SAGA } from './Sms/SendSmsSaga';
import { FORGET_PASSWORD_USER_SAGA } from './ForgetPassword/ForgetPasswordCreate';
import { FORGET_UUID_VERIFICATION_SAGA } from './ForgetPassword/ForgetPasswordVerification';
import { GET_GROUPED_REVENUE_SAGA } from './Funding/GroupedRevenueSourcesSaga';
import { GET_GROUPED_CURRENCY_SAGA } from './Funding/GroupedCurrencySaga';
import { GROUPED_TRANSACTION_TYPE_SAGA } from './Funding/GroupedTransactionTypeSaga';
import { GROUPED_REVENUE_TRANSACTION_TYPE_SAGA } from './Funding/GroupedRevenueTransactionTypeSaga';
import { GROUPED_TRANSACTION_WEEK_SAGA } from './Funding/GroupedTransactionWeek';
import { SAVE_USER_DATA_SAGA } from './ProfileAccounts/User';
import { GET_USER_PROFILE_DATA_SAGA } from './ProfileAccounts/UserProfile';
import { SAVE_ACTIVE_CUSTOMER_SAGA } from './ProfileAccounts/SaveActiveCustomer';
import { GET_LATEST_ADVANCED_SAGA } from './Funding/LatestAdvances';
import { GET_FEE_SAGA } from './Account/FeeSaga';
import { RESET_PASSWORD_USER_SAGA } from './ForgetPassword/ResetPassword';
import { GET_ACCOUNT_PREFERENCES_SAGA } from './ProfileAccounts/GetAccountPreferencesSaga';
import { UPDATE_ACCOUNT_PREFERENCES_SAGA } from './ProfileAccounts/UpdateAccountPreferencesSaga';
import getRevenueSourcesSaga from './ProfileAccounts/Integrations/GetRevenueSourcesSaga';
import { ADD_REVENUE_SOURCE_SAGA } from './ProfileAccounts/Integrations/AddRevenueSourceSaga';
import { GET_BUSINESS_SAGA } from './ProfileAccounts/Business/GetBusinessSaga';
import { UPDATE_BUSINESS_SAGA } from './ProfileAccounts/Business/UpdateBusinessSaga';
import { GET_ACCOUNTING_SOFTWARE_SAGA } from './ProfileAccounts/Integrations/GetAccountingSoftware';
import { GET_CUSTOMER_REVENUE_SOURCE_SAGA } from './AdvanceLedger/RevenueSource';
import { GET_GROUPED_ARROW_DATA_SAGA } from './Funding/FundingArrowSaga';
import { ADD_TEAM_USER_SAGA } from './Account/Administration/addTeamUser';
import { GET_CUSTOMER_USERS_SAGA } from './Account/Administration/getCustomerUsers';
import { REMOVE_TEAM_USER_SAGA } from './Account/Administration/removeTeamUser';
import { UPDATE_USER_ROLE_SAGA } from './Account/Administration/updateUserRole';
import { GET_DOCUMENTATION_SAGA } from './Documentation';
import { GET_UPDATE_REVENUE_SOURCE_SAGA } from './ProfileAccounts/Integrations/UpdateRevenueSourceSaga';
import { ACC_EDIT_SOFTWARE_SOURCE_SAGA } from './ProfileAccounts/Integrations/EditAccountingSoftware';
import { VERIFY_PHONE_NUMBER_SAGA } from './VerifyPhoneNumber/VerifyPhoneNumberSaga';
import { SIGNUP_SAGA } from './Signup/SignupSaga';
import { GET_DETAILS_FROM_CODE_SAGA } from './GetDetailsFromCode';
import { GET_CURRENCIES_SAGA } from './DbOptions/CurrenciesList';
import { CURRENCIES_OBJECT_SAGA } from './DbOptions/CurrenciesObject';
import { GET_CUSTOMER_BANK_ACCOUNT_COLLECTIONS_SAGA } from './Account/BankAccount/collections';
import { GET_CUSTOMER_BANK_ACCOUNT_PAYOUTS_SAGA } from './Account/BankAccount/payouts';
import { ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_SAGA } from './Account/BankAccount/addPayout';
import { ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_SAGA } from './Account/BankAccount/activatePayout';

// Wrap all sagas in a container
const rootSaga = function* rootSaga() {
  yield all([
    SEND_SMS_SAGA(),
    LOGIN_USER_SAGA(),
    BUSINESS_STATS_SAGA(),
    EMAIL_EXISTS_SAGA(),
    SAVE_STEPPER_SAGA(),
    CREATE_CUSTOMER_SAGA(),
    SEND_GRID_TEMPLATE_SAGA(),
    CURRENT_USER_ID_SAGA(),
    GET_GROUPED_REVENUE_SAGA(),
    GET_GROUPED_CURRENCY_SAGA(),
    GROUPED_TRANSACTION_TYPE_SAGA(),
    GROUPED_REVENUE_TRANSACTION_TYPE_SAGA(),
    GROUPED_TRANSACTION_WEEK_SAGA(),
    SAVE_USER_DATA_SAGA(),
    GET_USER_PROFILE_DATA_SAGA(),
    SAVE_ACTIVE_CUSTOMER_SAGA(),
    GET_LATEST_ADVANCED_SAGA(),
    GET_FEE_SAGA(),
    GET_ACCOUNT_PREFERENCES_SAGA(),
    UPDATE_ACCOUNT_PREFERENCES_SAGA(),
    getRevenueSourcesSaga(),
    ADD_REVENUE_SOURCE_SAGA(),
    GET_BUSINESS_SAGA(),
    UPDATE_BUSINESS_SAGA(),
    GET_ACCOUNTING_SOFTWARE_SAGA(),
    GET_GROUPED_ARROW_DATA_SAGA(),
    ADD_TEAM_USER_SAGA(),
    REMOVE_TEAM_USER_SAGA(),
    GET_DOCUMENTATION_SAGA(),
    GET_CUSTOMER_REVENUE_SOURCE_SAGA(),
    FORGET_PASSWORD_USER_SAGA(),
    FORGET_UUID_VERIFICATION_SAGA(),
    RESET_PASSWORD_USER_SAGA(),
    GET_UPDATE_REVENUE_SOURCE_SAGA(),
    ACC_EDIT_SOFTWARE_SOURCE_SAGA(),
    VERIFY_PHONE_NUMBER_SAGA(),
    SIGNUP_SAGA(),
    GET_DETAILS_FROM_CODE_SAGA(),
    GET_CUSTOMER_USERS_SAGA(),
    GET_CURRENCIES_SAGA(),
    CURRENCIES_OBJECT_SAGA(),
    UPDATE_USER_ROLE_SAGA(),
    GET_CUSTOMER_BANK_ACCOUNT_COLLECTIONS_SAGA(),
    GET_CUSTOMER_BANK_ACCOUNT_PAYOUTS_SAGA(),
    ADD_CUSTOMER_BANK_ACCOUNT_PAYOUT_SAGA(),
    ACTIVATE_CUSTOMER_BANK_ACCOUNT_PAYOUT_SAGA()
  ]);
};

export default rootSaga;
