export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      website: string;
      employees: string[];
      registered_in: string;
      business_type: string;
      trading_duration: string[];
      turnover: string[];
      funding_needs: number;
      reporting_currency: string;
      revenue_sources: any;
      sector: string;
      reg_number: string;
      uuid: string;
      country_code: string[];
      company_name: string;
      user: {
        phone: string;
        is_owner: boolean;
        eligible: boolean;
        approved: boolean;
        username: string;
        email: string;
        first_name: string;
        last_name: string;
        onboarded_at: string;
        terms: boolean;
        has_customer: boolean;
      };
    };
  }
) {
  switch (action.type) {
    case 'UPDATE_BUSINESS_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'UPDATE_BUSINESS_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'UPDATE_BUSINESS_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'UPDATE_BUSINESS_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
