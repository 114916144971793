export default function reducer(
  state = {
    data: null
  },
  action: {
    type: string;
    payload: {
      data: {
        UserSettings: {
          email_notifications: boolean;
          notify_advance_deposit: boolean;
          notify_repayment: boolean;
          customer_user: number;
        };
      };
    };
  }
) {
  switch (action.type) {
    case 'UPDATE_ACCOUNT_PREFERENCES_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'UPDATE_ACCOUNT_PREFERENCES_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        data: action.payload
      };
    }
    case 'UPDATE_ACCOUNT_PREFERENCES_FAILED': {
      return { ...state, changingStatus: 'failed', data: action.payload };
    }
    case 'UPDATE_ACCOUNT_PREFERENCES_NET_FAILED': {
      return {
        ...state,
        changingStatus: 'netFailed',
        data: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
