import { IReduxRevenueActions, IReduxRevenueSourcesState } from './models';
import defaultState from './store';
import { ReduxRevenueSourcerType } from './types';

export default function revenueSourcesReducer(
  state: IReduxRevenueSourcesState = defaultState,
  action: IReduxRevenueActions
) {
  switch (action.type) {
    case ReduxRevenueSourcerType.SET_ALL_REVENUE_MASTER:
      return { ...state, allRevenueMaster: action.payload };
    case ReduxRevenueSourcerType.SET_VERIFIED_REVENUE_MASTER:
      return { ...state, verifiedRevenueMaster: action.payload };
    case ReduxRevenueSourcerType.SET_REVENUE_WITH_REQUEST:
      return { ...state, revenueWithRequest: action.payload };
    default:
      return state;
  }
}
