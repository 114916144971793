import { IReduxCommonActions, IReduxCommonState } from './models';
import defaultState from './store';
import { ReduxCommonType } from './types';

export default function commonReducer(
  state: IReduxCommonState = defaultState,
  action: IReduxCommonActions
) {
  switch (action.type) {
    case ReduxCommonType.SET_COUNTRIES:
      return { ...state, countries: action.payload };
    default:
      return state;
  }
}
