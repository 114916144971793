export enum ReduxFundingType {
  SET_ALL_REPORT_REVENUES_LEDGER = 'SET_ALL_REPORT_REVENUES_LEDGER',
  SET_COUNT_RESULTS_REVENUES_LEDGER = 'SET_COUNT_RESULTS_REVENUES_LEDGER',
  SET_HIDDEN_COLUMNS_REVENUES_LEDGER = 'SET_HIDDEN_COLUMNS_REVENUES_LEDGER',
  SET_REVENUE_PARAMS = 'SET_REVENUE_PARAMS',
  SET_FILTERED_BY_REVENUES_LEDGER = 'SET_FILTERED_BY_REVENUES_LEDGER',
  SET_ALL_FILTERS_REVENUES_LEDGER = 'SET_ALL_FILTERS_REVENUES_LEDGER',
  SET_AVAILABLE_FILTERS_REVENUES_LEDGER = 'SET_AVAILABLE_FILTERS_REVENUES_LEDGER',
  SET_PERIODS_FILTER_REVENUES_LEDGER = 'SET_PERIODS_FILTER_REVENUES_LEDGER',
  SET_ALL_REPORT_ADVANCED_LEDGER = 'SET_ALL_REPORT_ADVANCED_LEDGER',
  SET_HIDDEN_COLUMNS_ADVANCED_LEDGER = 'SET_HIDDEN_COLUMNS_ADVANCED_LEDGER',
  SET_ALL_REPORT_TRANSACTIONS = 'SET_ALL_REPORT_TRANSACTIONS',
  SET_HIDDEN_COLUMNS_TRANSACTIONS = 'SET_HIDDEN_COLUMNS_TRANSACTIONS'
}
